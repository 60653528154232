<template>
  <svg width="224" height="218" viewBox="0 0 1097 218" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M948.407 5.79287C920.975 15.6947 895.067 41.2108 882.875 70.1546C871.445 97.5749 877.922 148.226 896.21 171.838C903.068 180.979 903.83 180.979 909.926 174.885C916.403 168.411 916.403 167.268 907.259 152.035C894.686 129.946 894.305 89.5773 906.497 66.727C928.214 25.5965 977.744 8.0779 1021.18 25.2156C1039.85 32.8324 1065.37 59.8719 1071.85 79.2947C1077.95 97.9558 1074.52 132.993 1064.99 151.654C1056.99 166.507 1056.99 167.649 1063.47 173.362C1071.09 180.598 1073.76 178.693 1084.42 157.747C1096.62 133.755 1099.28 109.762 1092.42 83.8647C1079.47 32.4516 1038.7 0.461136 987.65 0.841974C973.934 0.841974 956.408 3.127 948.407 5.79287Z"
      fill="black"
    />
    <path
      d="M969.362 58.7294C959.075 69.7737 958.694 80.818 968.6 89.9581C979.649 100.241 978.506 110.143 966.695 107.858C959.075 106.715 957.17 107.858 957.17 114.332C957.17 120.425 959.456 122.329 966.695 122.329H976.22V162.317V201.925L960.218 196.212C951.455 193.165 941.168 187.834 937.358 184.406C930.5 178.313 929.738 178.693 922.499 185.929L914.498 193.927L932.405 204.21C946.121 212.588 956.789 215.254 978.125 216.777C1007.08 218.681 1023.08 215.254 1044.42 201.925C1056.61 194.689 1056.61 193.927 1050.52 187.072C1044.42 180.217 1044.04 180.217 1031.47 187.453C1024.61 191.642 1013.56 196.593 1007.08 198.497L995.27 201.925V161.937V122.329H1004.8C1012.03 122.329 1014.32 120.425 1014.32 114.332C1014.32 107.858 1012.42 106.715 1004.8 107.858C992.984 110.143 991.46 99.8599 1002.89 92.624C1013.94 85.7689 1013.18 63.6803 1002.13 56.0635C989.936 47.6851 978.887 48.4467 969.362 58.7294ZM996.794 70.9162C999.461 78.1521 988.793 88.4348 981.935 85.7689C975.077 83.103 974.315 66.3461 981.173 64.0611C987.65 61.7761 994.127 64.8228 996.794 70.9162Z"
      fill="black"
    />
    <path
      d="M0.859863 109C0.859863 145.18 0.859863 145.18 10.3849 145.18C18.7669 145.18 19.9099 143.656 19.9099 132.612C19.9099 113.57 24.1009 112.808 34.0069 129.946C40.8649 140.99 45.8179 145.18 52.6759 145.18C57.6289 145.18 61.8199 144.418 61.8199 143.275C61.8199 142.133 56.4859 132.993 50.0089 122.329L38.1979 103.287L48.1039 89.9581C60.2959 74.3438 60.2959 72.8204 49.2469 72.8204C43.1509 72.8204 36.6739 77.3905 30.1969 86.9114L19.9099 101.383V86.9114C19.9099 74.3438 18.7669 72.8204 10.3849 72.8204C0.859863 72.8204 0.859863 72.8204 0.859863 109Z"
      fill="black"
    />
    <path
      d="M69.4399 100.622C69.4399 124.995 70.5829 129.565 78.2029 136.801C89.6329 147.465 108.683 147.846 118.97 137.563C124.685 131.85 126.59 124.234 127.352 107.096C127.352 94.5282 127.733 81.9605 128.114 79.2947C128.495 77.0096 124.685 74.3438 120.113 73.5821C111.731 72.4396 111.35 73.5821 111.35 96.4324C111.35 123.472 107.921 131.47 97.6339 129.184C91.5379 128.042 90.3949 123.853 89.2519 100.241C88.1089 73.9629 87.7279 72.8204 78.9649 72.8204C69.8209 72.8204 69.4399 73.5821 69.4399 100.622Z"
      fill="black"
    />
    <path
      d="M145.64 105.953C145.64 123.472 145.64 138.705 145.64 139.848C145.64 140.99 156.689 142.133 170.405 142.514C194.027 143.275 195.17 142.895 195.17 134.135C195.17 125.757 194.027 125.376 178.025 126.899C163.166 128.804 160.88 128.042 160.88 121.949C160.88 116.236 163.547 114.713 174.215 114.713C184.883 114.713 187.55 113.189 187.55 107.477C187.55 101.002 185.645 100.241 174.215 102.145C162.404 104.049 160.88 103.287 160.88 95.6707C160.88 88.4348 162.404 87.6731 177.263 88.8156C190.979 89.9581 193.265 88.8156 193.265 82.7222C193.265 76.2479 190.217 75.1054 169.643 75.1054L145.64 74.7246V105.953Z"
      fill="black"
    />
    <path
      d="M209.648 75.4862C208.886 77.3904 208.505 93.0048 208.505 110.904C208.886 140.99 209.648 143.275 217.268 144.418C224.888 145.56 225.65 144.037 225.65 130.327C225.65 115.093 226.031 114.713 239.366 114.713C252.701 114.713 252.701 115.093 251.558 128.804C250.415 141.752 251.558 143.275 259.178 143.275C263.75 143.275 267.56 142.133 267.56 140.229C267.56 138.705 267.56 122.71 267.56 104.811C267.56 73.2012 267.179 72.4395 259.178 73.582C251.939 74.3437 250.796 76.6288 251.558 86.9114C252.701 99.0982 252.32 99.4791 239.366 99.4791C226.412 99.4791 225.65 98.7174 225.65 86.1497C225.65 75.4862 224.126 72.8204 218.411 72.8204C214.22 72.8204 210.41 73.9629 209.648 75.4862Z"
      fill="black"
    />
    <path
      d="M286.61 109C286.61 142.514 286.991 145.18 294.23 145.18C301.088 145.18 301.85 142.514 301.85 123.091V101.383L314.423 121.949C322.424 135.278 329.663 142.895 335.378 143.656C343.379 145.18 343.76 144.418 343.76 109C343.76 75.4863 343.379 72.8204 336.14 72.8204C329.282 72.8204 328.52 75.4863 328.52 94.5282V116.617L315.947 96.0515C307.946 82.7222 300.707 75.1054 294.992 74.3438C286.991 72.8204 286.61 73.5821 286.61 109Z"
      fill="black"
    />
    <path
      d="M362.81 109V143.276H387.575C411.197 143.276 412.34 142.895 412.34 134.135C412.34 125.757 411.197 125.376 395.195 126.899C380.336 128.804 378.05 128.042 378.05 121.949C378.05 116.236 380.717 114.713 391.385 114.713C402.053 114.713 404.72 113.189 404.72 107.477C404.72 101.002 402.815 100.241 391.385 102.145C379.574 104.049 378.05 103.287 378.05 95.6707C378.05 88.4348 379.574 87.6731 394.433 88.8156C408.149 89.9582 410.435 88.8156 410.435 82.7222C410.435 76.248 407.387 75.1055 386.813 75.1055L362.81 74.7246V109Z"
      fill="black"
    />
    <path
      d="M496.16 109.762C496.16 144.799 496.541 145.561 504.923 144.418C512.543 143.275 513.305 141.371 512.162 123.091C511.781 112.428 512.162 103.287 512.924 103.287C514.067 103.287 520.163 112.047 526.259 123.091C534.641 137.563 539.975 142.895 547.214 143.656L557.12 144.799V108.619C557.12 73.2012 556.739 72.4395 548.738 73.5821C540.737 74.7246 539.975 76.2479 541.118 94.5282C541.88 105.572 541.88 114.713 540.737 114.713C539.594 114.713 533.498 105.572 527.021 94.5282C518.258 79.2946 513.686 74.7246 506.066 74.3437L496.16 73.5821V109.762Z"
      fill="black"
    />
    <path
      d="M580.361 103.288C574.265 118.902 568.169 134.897 566.645 138.325C564.359 143.656 565.883 145.18 573.503 145.18C578.837 145.18 583.79 142.514 585.314 139.467C586.457 136.04 592.553 133.755 600.935 133.755C609.317 133.755 615.413 136.04 616.556 139.467C618.08 142.514 623.033 145.18 627.986 145.18C639.035 145.18 638.654 146.322 629.891 125.757C626.081 116.617 620.366 101.383 616.937 91.8624C611.603 77.7714 608.936 74.7246 600.935 74.7246C592.553 74.7246 589.886 78.1522 580.361 103.288ZM608.174 109.762C610.46 117.379 609.698 118.521 601.316 118.521C589.886 118.521 590.267 119.283 595.601 103.288C600.173 90.339 602.84 91.8624 608.174 109.762Z"
      fill="black"
    />
    <path
      d="M650.465 82.3414C643.226 89.1965 640.94 95.6707 640.94 109C640.94 138.705 663.038 152.416 691.994 141.371C700.757 138.325 701.9 135.659 701.9 120.806V103.668L687.803 104.43C679.802 104.811 673.706 106.334 674.468 107.858C674.849 109.762 675.23 112.428 675.23 114.713C675.23 116.617 677.897 118.521 680.945 118.521C683.993 118.521 686.66 121.187 686.66 124.234C686.66 128.042 682.85 129.946 675.23 129.946C666.086 129.946 662.657 127.661 659.609 119.664C653.894 104.811 659.99 90.339 672.563 88.8156C678.278 88.054 682.85 89.1965 682.85 91.4815C682.85 93.7665 687.041 95.6707 692.375 95.6707C703.805 95.6707 704.567 90.7198 694.28 80.4372C683.231 69.3929 662.276 70.1546 650.465 82.3414Z"
      fill="black"
    />
    <path
      d="M717.14 109V143.276H741.905C765.146 143.276 766.67 142.895 766.67 134.516C766.67 126.138 765.146 125.757 750.668 126.899C737.714 127.661 734.285 126.899 733.142 121.187C731.999 115.855 734.666 114.713 747.239 114.713C759.812 114.713 762.86 113.189 762.86 107.477C762.86 101.764 760.193 100.622 748.763 101.002C738.095 101.383 734.285 99.8599 734.285 95.6707C734.285 91.4815 738.476 89.9582 750.668 90.339C764.384 90.7198 766.67 89.5773 766.67 82.7222C766.67 75.4863 764.384 74.7246 741.905 74.7246H717.14V109Z"
      fill="black"
    />
    <path
      d="M781.91 109V145.18H806.675C831.059 145.18 831.44 144.799 831.44 135.278C831.44 125.757 830.678 125.376 816.2 127.28L800.96 129.184V101.002C800.96 73.5821 800.579 72.8204 791.435 72.8204C781.91 72.8204 781.91 72.8204 781.91 109Z"
      fill="black"
    />
    <path
      d="M446.63 91.8623C446.63 106.715 446.249 107.096 433.295 107.096C426.056 107.096 419.96 108.619 419.96 110.904C419.96 112.808 426.056 114.713 433.295 114.713C446.249 114.713 446.63 115.093 446.63 129.946C446.63 140.99 448.154 145.18 452.345 145.18C456.536 145.18 458.06 140.99 458.06 129.946C458.06 115.093 458.441 114.713 471.395 114.713C478.634 114.713 484.73 112.808 484.73 110.904C484.73 108.619 478.634 107.096 471.395 107.096C458.441 107.096 458.06 106.715 458.06 91.8623C458.06 80.818 456.536 76.6288 452.345 76.6288C448.154 76.6288 446.63 80.818 446.63 91.8623Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'KuehneLogo',
};
</script>
